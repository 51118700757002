<template>
<div class="inter">
    <div class="mask" v-if="isMaskVisible">
        <!-- 加载动画start -->
        <loadAction :loading="isLoad" />
        <!-- 加载动画end --> 
    </div>  
    <tablelist :tableArray="rechangeData"  :defaultTable="defaultTable" @getList="getList" :isPage="true" :total="total" ref="table" :pageSize="pageSize" @currentMethod="currentMethod"></tablelist>
</div>
</template>
<script>
  // import { Message } from 'element-ui'; 
 import api from "../../http/api";  
export default{
    data(){
        return{
          total:0,//总页数
          pageSize:22,//分页
          pageIndex:1,//当前页码
          isMaskVisible:true,
          isLoad: true,  //加载动画
           rechangeData:[{
                key: 'id',
                name: '积分卡编号',
                width: '10%',
            },
            {
                key: 'card_secret_key',
                name: '积分卡',
                width: '30%',
            },
            {
                key: 'integral',
                name: '积分数量',
                width: '10%',
            },
            {
                key: 'remaining_integral',
                name: '剩余积分',
                width: '10%',
            },
            {
                key: 'receive_time',
                name: '激活时间',
                width: '20%',
            },
            {
                key: 'overdue_time',
                name: '失效时间',
                width: '20%',
            },
        ],//表格头部
        defaultTable:[],//默认数据
       }
    },
    components:{
      loadAction: () => import("../components/load"),
      tablelist:()=>import('../components/table'),
   },
    created(){
      // this.getList();
   },
   activated(){
     this.isLoad =true
     this.isMaskVisible = true
     this.getList();
   },
   methods:{
    currentMethod(data){
        this.getList(data)
    },
    //获取列表数据
    async getList(data){
        let that=this
        that.pageIndex=data?data:that.pageIndex
        let result=await api.getHistoryDetailes({
            page_size:that.pageSize,
            page_index:that.pageIndex
        })
        if(result.code==200){
            let res=result.response
            that.total=res.total
            that.defaultTable=res&&res.data.length!=0?res.data:[]
            setTimeout(() => {
                this.isMaskVisible = false
                this.isLoad = false}, 200);
        }
        else {
            setTimeout(() => {
                this.isMaskVisible = false
                this.isLoad = false}, 200);
        }
    }
   }
}
</script>
<style lang="less" scoped>
.inter{
    width:100%;
    height:100%;
}
.mask {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 9999;
    background-color: rgba(255, 255, 255, 0.98); /* 半透明黑色背景 */
    z-index: 1000; /* 确保它在其他元素之上 */
}
/deep/ .el-pagination {
left:2px;
}
</style>